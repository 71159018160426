import { Col, Row, Button, Input, message, Cascader, Typography, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const Jumpserver = () => {
  const apiRef = useGridApiRef();
  const [jumpServerData, setJumpServerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState();
  const [notification, setNotification] = message.useMessage();
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const debouncedValue = useDebounce(search, 500);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [currentPage, setCurrentPage] = useState(0);

  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  const { Text } = Typography;

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = jumpServerData
      .filter((jumpServerdata) =>
        jumpServerdata.serverName.toLowerCase().includes(value.toLowerCase())
      )
      .map((jumpServerdata) => ({
        label: jumpServerdata.serverName,
        value: jumpServerdata.serverName,
      }));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    const fetchJumpserver = () => {
      let pageDetails = {
        pageId: currentPage + 1,
        pageSize: currentPageModel.pageSize,
        search: debouncedValue,
        filter: {
          filterBy: filterBy,
          value: filteredValue,
        },
        token: AUTH_TOKEN(),
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
      };
      setIsLoading(true);
      axios
        .post(`${REACT_APP_PAM_API}/jumpserver/listJumpServer`, pageDetails, {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setJumpServerData(
            res?.data?.jumpServers?.map((data, i) => ({
              id: i + 1,
              ...data,
            }))
          );

          setTotalCount(res?.data?.total);
          setCurrentPageModel({
            pageSize: 10,
            page: currentPage,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err?.response?.data?.message === "Invalid credentials" ||
            err?.response?.status === 401
          ) {
            logOutUser();
          } else {
            notification.open({
              type: "error",
              content: "Unable to fetch jump server",
            });
          }
        });
    };
    fetchJumpserver();
  }, [paginationParams, currentPage, debouncedValue, filteredValue]);
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const columns = [
    {
      headerName: "Server Name",
      field: "serverName",
      key: "serverName",
      minWidth: 150,
    },
    {
      headerName: "Jump Host Address",
      field: "publicIpAddress",
      key: "publicIpAddress",
      minWidth: 150,
    },
    {
      headerName: "Status",
      field: "status",
      key: "status",
      minWidth: 110,
      renderCell: (val) => {
        let statusVal =
          val.row.status.toLowerCase() === "active"
            ? "Active"
            : val.row.status.toLowerCase() === "in progress"
            ? "In progress"
            : "Inactive";
        return (
          <>
            <Text
              type={
                val.row.status.toLowerCase() === "active"
                  ? "success"
                  : val.row.status.toLowerCase() === "in progress"
                  ? "warning"
                  : "danger"
              }
            >
              {statusVal}
            </Text>
          </>
        );
      },
    },
    {
      headerName: "View Connections",
      field: "viewConnections",
      key: "viewConnection",
      minWidth: 150,
      renderCell: (val) => (
        <Link
          to={`/pam/jumpServer-connections/${val.row.publicIpAddress}?hostname=${val.row.serverName}`}
        >
          Show
        </Link>
      ),
    },
    {
      headerName: "Action",
      field: "action",
      key: "action",
      minWidth: 150,
      renderCell: (val) => (
        <Link
          to={`/pam/jumpServer-connections/createConnection?serverName=${val.row.serverName}&id=${val.row.id}&ip=${val.row.publicIpAddress}`}
        >
          Assign Connection
        </Link>
      ),
    },
  ];

  const option = [
    {
      value: "status",
      label: "Status",
      children: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
        {
          label: "In progress",
          value: "In progress",
        },
      ],
    },
  ];
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  // const handleFilter = (_, data) => {
  //   setfilterBy(data ? data[0]?.value : "");
  //   setFilteredValue(data ? data[1]?.label : "");
  //   setCurrentPage(1);
  // };

  const handleFilter = (value) => {
    if (value) {
      setfilterBy("status");
      setFilteredValue(value);
    } else {
      setfilterBy("");
      setFilteredValue("");
    }
    setCurrentPage(1);
  };

  const filterOption = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
    {
      label: "In progress",
      value: "In progress",
    },
  ];

  const calculateColumnWidths = (data, columnsToAutoSize, includeHeaders = false) => {
    const columnWidths = {};

    columnsToAutoSize.forEach((col) => {
      let maxWidth = includeHeaders ? col.length * 13 : 0;

      data.forEach((row) => {
        const value = row[col];
        const width = value ? value.toString().length * 8 : 0;
        if (width > maxWidth) {
          maxWidth = width;
        }
      });

      columnWidths[col] = maxWidth;
    });
    console.log(columnWidths);

    return columnWidths;
  };

  // Calculate column widths
  const columnWidths = calculateColumnWidths(
    jumpServerData,
    ["serverName", "publicIpAddress", "viewConnections", "status"],
    true
  );

  // Apply the calculated widths to the columns
  const adjustedColumns = columns.map((col) => ({
    ...col,
    width: columnWidths[col.field] || col.width,
  }));

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Jump Server</h2>
            </Col>
            <Col>
              <Link to={"/pam/jumpServer/add"}>
                <Button type="primary">Add Jump Server</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Status</p>

                      <Select
                        placeholder="Select Status"
                        allowClear
                        onChange={handleFilter}
                        showSearch={filter}
                        options={[
                          {
                            label: "Active",
                            value: "active",
                          },
                          {
                            label: "Inactive",
                            value: "inactive",
                          },
                          {
                            label: "In Progress",
                            value: "In progress",
                          },
                        ]}
                      />
                      {/* <Cascader
                        options={option}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                      /> */}
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <AutoComplete
                          placeholder="Search Server Name"
                          allowClear
                          onSearch={handleSearch}
                          options={options}
                          onChange={(value) => {
                            setSearch(value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DataGrid
                apiRef={apiRef}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={jumpServerData}
                paginationMode="server"
                columns={adjustedColumns}
                rowCount={totalCount}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
                paginationModel={currentPageModel}
                page={currentPage}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Jumpserver;
