import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Tag,
  Button,
  Input,
  message,
  Cascader,
  notification,
  Modal,
  Dropdown,
  Typography,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";

import { useLocation, Link, useParams } from "react-router-dom";
import {
  AUTH_TOKEN,
  REACT_APP_API_DID_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API,
  JUMPSERVER_CONNECTION_PORT,
} from "../../../constants";
import { logOutUser } from "../../../common";
import axios from "axios";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const Context = React.createContext({
  name: "Default",
});

const PopupModal = (isModalOpen, handleCancel, content, modalType, isNotification) => {
  const handleCopy = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      handleCancel();
      isNotification.open({
        type: "success",
        content: "Copied to clipboard",
      });
    } catch (err) {
      isNotification.open({
        type: "error",
        content: "Failed to copy",
      });
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <Modal
      title={modalType === "RDP" ? "Password/SSHKeys" : "SSH Command"}
      open={isModalOpen}
      okText="Copy"
      onOk={() => handleCopy(content)}
      onCancel={handleCancel}
      centered={true}
      width={modalType === "RDP" ? "450px" : "70%"}
    >
      {modalType === "RDP" ? (
        <span>{content ? content : "No Password"}</span>
      ) : (
        <span>{content ? content : "No SSH Command"}</span>
      )}
    </Modal>
  );
};

const Connection = () => {
  const apiRef = useGridApiRef();
  const [connectionData, setConnectionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [endpoints, setEndpoints] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  let userEmail = localStorage.getItem("UserName");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  const hostname = queryParams.get("hostname");
  // const [notify, contextHolder] = notification.useNotification();
  const [isNotification, setNotification] = message.useMessage();
  const [endpointFilter, setEndpointFilter] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const { publicIpAddress } = useParams();
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [connectionMode, setConnectionMode] = useState(null);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const [filterEndpointId, setFilterEndpointId] = useState();

  let endpointId = [];
  const { Text } = Typography;

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = connectionData
      .filter((connectiondata) => connectiondata.user.toLowerCase().includes(value.toLowerCase()))
      .map((connectiondata) => ({
        label: connectiondata.user,
        value: connectiondata.user,
      }));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchConnection();
    return () => {
      if (hostAddress) {
        connectionData.some((item) =>
          item.endpoints.some((endpoint) => {
            if (endpoint.publicIpAddress === hostAddress) {
              endpointId = item.endpoints.map((end) => end.instanceId);
              console.log(endpointId);
            }
          })
        );
      }
    };
  }, [filterBy, filteredValue, debouncedValue, endpointFilter, paginationParams, currentPage]);

  useEffect(() => {
    fetchInstance();
    fetchWallet();
    fetchConnectionMode();
  }, []);

  const fetchUserPassword = async (user, walletUser, protocol, hostName) => {
    let payload = {
      orgId: ORG_ID(),
      epmUserName: user,
      walletUser: walletUser,
      protocol: protocol,
      hostname: hostName,
    };
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/walletService/listCredentialsforUser`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setModalContent(res?.data?.password);
      setModalType(protocol);
      setIsModalOpen(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchSSHCommands = async (user, machineName) => {
    let payload = {
      orgId: ORG_ID(),
      machineName: machineName,
      username: user,
    };
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/secretservice/generateSSHcommand`,
        payload, {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setModalContent(res?.data?.command);
      setIsModalOpen(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchConnectionMode = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/secretservice/getConnectionMode`,
        payload, {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setConnectionMode(res.data.mode);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "publicIpAddress",
      headerName: "Jump Server Url",
      width: 150,
      key: "publicIpAddress",
      renderCell: (val) => (
        <span style={{ whiteSpace: "nowrap" }}>{val?.row?.publicIpAddress}</span>
      ),
    },
    { field: "user", headerName: "User", key: "user" },
    {
      field: "walletuser",
      headerName: "Wallet User",
      key: "walletuser",
      renderCell: (val) => (
        <span>
          <Tag color="blue" key={val.row.walletuser}>
            {val.row.walletuser}
          </Tag>
        </span>
      ),
    },
    { field: "protocol", headerName: "Protocol", key: "protocol" },
    {
      field: "endpoints",
      headerName: "Endpoints",
      key: "endpoints",
      renderCell: (val) => (
        <span>
          {val.row.endpoints.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      key: "status",
      renderCell: (val) => {
        let statusVal = val.row.status[0];
        return (
          <>
            <Text type={statusVal !== "Inactive" ? "success" : "danger"}> {statusVal}</Text>
          </>
        );
      },
      displayRender: false,
    },
    {
      field: "action",
      headerName: "Action",
      key: "action",
      renderCell: (record) => {
        const items = [
          {
            key: "1",
            label: (
              <Link
                onClick={() => {
                  fetchUserPassword(
                    record?.row?.user,
                    record?.row?.walletuser,
                    record?.row?.protocol,
                    record?.row?.endpoints[0]?.hostname
                  );
                }}
              >
                Password/SSH
              </Link>
            ),
          },
        ];

        if (connectionMode !== 1 && record.row.protocol === "RDP") {
          items.push({
            key: "2",
            label: (
              <Link
                onClick={() => {
                  return window.open(
                    `${REACT_APP_API}/api/v1/secretservice/generateRDPFile?machineName=${
                      record?.row?.endpoints[0]?.hostname
                    }&userName=${record?.row?.user}&orgId=${ORG_ID()}`
                  );
                }}
              >
                Download RDP
              </Link>
            ),
          });
        }

        if (record.row.protocol === "SSH") {
          items.push({
            key: "3",
            label: (
              <Link
                onClick={() => {
                  fetchSSHCommands(record?.row?.user, record?.row?.endpoints[0]?.hostname);
                }}
              >
                SSH Command
              </Link>
            ),
          });
        }

        return (
          <Dropdown
            placement="bottomLeft"
            menu={{
              items: items,
            }}
          >
            <Button type="text" shape="circle" icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  if (connectionMode !== 2) {
    const connectColumn = {
      field: "connect",
      headerName: "Connect",
      key: "connect",
      minWidth: 150,
      renderCell: (val) => (
        <span>
          <Link
            disabled={val.row?.status.includes("Inactive")}
            onClick={() => {
              let status = val?.row?.status;
              if (status.includes("Active")) {
                api["info"]({
                  message: "Entering Servlet:",
                  description:
                    "Please approve credentials to proceed and Session will be recorded.",
                  placement: "topLeft",
                });
                return window.open(
                  `http://${
                    val?.row?.publicIpAddress
                  }:${JUMPSERVER_CONNECTION_PORT}/guacamole-servlet/?orgId=${ORG_ID()}&tenantId=${DOMAIN_ID()}&hostName=${
                    val?.row?.endpoints[0]?.publicIpAddress
                  }&userName=${
                    val.row.user
                  }&ipAddress=localhost&bearerToken=${AUTH_TOKEN()}&loggedInUser=${userEmail}&protocol=${
                    val.row.protocol
                  }&hostlabel=${val.row.endpoints[0].hostname}`,
                  `width=1024`,
                  `height=768`
                );
              }
            }}
          >
            Connect Now
          </Link>
        </span>
      ),
    };

    columns.splice(6, 0, connectColumn);
  }

  const calculateColumnWidths = (data, columnsToAutoSize, includeHeaders = false) => {
    const columnWidths = {};

    columnsToAutoSize.forEach((col) => {
      let maxWidth = includeHeaders ? col.length * 13 : 0;

      data.forEach((row) => {
        const value = row[col];
        const width = value ? value.toString().length * 10 : 0;
        if (width > maxWidth) {
          maxWidth = width;
        }
      });

      columnWidths[col] = maxWidth;
    });

    return columnWidths;
  };

  // Calculate column widths
  const columnWidths = calculateColumnWidths(
    connectionData,
    [
      "publicIpAddress",
      "user",
      "walletuser",
      "protocol",
      "endpoints",
      "status",
      "connect",
      "action",
    ],
    true
  );

  // Apply the calculated widths to the columns
  const adjustedColumns = columns.map((col) => ({
    ...col,
    width: columnWidths[col.field] || col.width,
  }));

  const fetchConnection = () => {
    let pageDetails = {
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search,
      filter: {
        filterBy: publicIpAddress ? "publicipaddress" : filterBy,
        value: publicIpAddress ? publicIpAddress : filteredValue,
      },
      endpoints: filterBy === "endpoint" ? endpointFilter : [],
      token: AUTH_TOKEN(),
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/connections/listConnections`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setTotalCount(res?.data?.total);
        const data = res?.data?.jumpServerConnections.map((data, index) => {
          return {
            ...data,
            status: data?.endpoints?.map((i) => (i.status === false ? "Inactive" : "Active")),
            id: index + 1,
          };
        });
        setConnectionData(data);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch connections",
          });
        }
      });
  };

  const fetchInstance = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoints(
          res?.data?.instances.map((ins) => ({
            value: ins.instanceId,
            label: (
              <div>
                {ins.hostName} <b>{`(${ins.publicIp})`}</b>
              </div>
            ),
            ipAddress: ins.publicIpAddress,
          }))
        );
        let endpointId = res?.data?.instances.map((ins) => ({
          hostname: ins.hostName,
          id: ins.instanceId,
        }));

        let id = endpointId.find((name) => name.hostname === hostname);
        if (id) {
          setFilterEndpointId([id.id]);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Invalid credentials" || err?.response?.status === 401) {
          logOutUser();
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchWallet = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageNumber: 1,
      pageSize: 100,
      filter: "",
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setWalletData(
          res?.data?.users?.map((user) => ({
            value: user.walletId,
            label: user.emailAddress ? user.emailAddress : "-",
          }))
        );
      });
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const option = [
    {
      value: "endpoint",
      label: "Endpoints",
      children: endpoints,
    },
    {
      value: "walletuser",
      label: "Wallet User",
      children: walletData,
    },
    {
      value: "protocol",
      label: "Protocol",
      children: [
        {
          value: 1,
          label: "SSH",
        },
        {
          value: 2,
          label: "RDP",
        },
        {
          value: 3,
          label: "VNC",
        },
        {
          value: 4,
          label: "Telnet",
        },
      ],
    },
  ];

  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) => {
    console.log(path, inputValue, "inputValue");
    return path.some((option) => {
      const label = option?.label?.props?.children[0]
        ? option.label.props.children[0].toLowerCase()
        : option.label.toLowerCase();
      return label.indexOf(inputValue.toLowerCase()) > -1;
    });
  };
  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    if (data && data[0]?.value === "endpoint") {
      let endpoint = data[1].label.props.children[0];
      setFilteredValue(data ? endpoint : "");
    } else {
      setFilteredValue(data ? data[1]?.label : "");
    }
    setEndpointFilter(data ? [data[1]?.value] : []);
    setPaginationParams({ current: 1, pageSize: 10 });
  };

  return (
    <>
      {setNotification}
      {PopupModal(
        isModalOpen,
        () => setIsModalOpen(false),
        modalContent,
        modalType,
        isNotification
      )}
      <Row className="content-conatiner">
        {contextHolder}

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Connections on Jump Server</h2>
            </Col>
            <Col>
              <Link to={"/pam/jumpServer-connections/createConnection"}>
                <Button type="primary">Create Connection</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>

                      <Cascader
                        options={option}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                        // defaultValue={
                        //   publicIpAddress && ["endpoint", `${hostname}(${publicIpAddress})`]
                        // }
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>

                      <div>
                        <AutoComplete
                          placeholder="Search User"
                          allowClear
                          onSearch={handleSearch}
                          options={options}
                          onChange={(value) => {
                            setSearch(value);
                          }}
                        />

                        {/* <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (paginationParams.current !== 1) {
                              setPaginationParams({ current: 1, pageSize: 10 });
                            }
                          }}
                        /> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DataGrid
                apiRef={apiRef}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={connectionData}
                columns={adjustedColumns}
                paginationMode="server"
                rowCount={totalCount}
                loading={isLoading}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                paginationModel={currentPageModel}
                page={currentPage}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Connection;
